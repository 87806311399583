/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId } from "../Helpers/Config";
var Parser = require("html-react-parser");
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { connect } from "react-redux";
import innerBanner from "../../common/images/inner-banner.jpg";
import menuImg from "../../common/images/press1.jpg";
import Slider from "react-slick";
const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  dots: true,
  arrows: false,
  adaptiveHeight: true,
};

import {
  GET_CAREERDATA,
  GET_CAREERCONTENT,
  GET_GLOBAL_SETTINGS,
} from "../../actions";

var Parser = require("html-react-parser");

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: "",
        mobile: "",
        email: "",
        file: "",
        message: ""
      },
    };
  

  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleFormSubmit = () => {
    const formPayload = this.state.fields;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      customer_first_name: formPayload.name,
      customer_emailaddress: formPayload.email,
      customer_phonenumber: formPayload.mobile,
      subject: formPayload.subject,
      customer_message: formPayload.message,
      contactus_extra_field2: formPayload.order_number,
    };

    this.props.getContactData(qs.stringify(postObject));
  };

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.contactdata !== this.props.contactdata) {
      if (nextProps.contactdata[0].status === "ok") {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    }
  }
  

  componentDidMount() {
  }

  render() {
    return (
      <div className="menumain-div career-maindiv">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="container">
            <div className="common-inner-banner">
              <div className="inner-banner-content">
                <p>Events</p>
              </div>
            </div>

            <div className="menu-blckdiv">
              <div className="menu-blck-heading career-head">
                <p>Whether you are planning an intimate dinner for friends, a corporate lunch, or an extravagant party, you will find the perfect setting at Beirut Grill.<br></br>
                Our restaurant also feature off-site catering and a full-service event production team that will make sure every detail is in place, so you don’t have to.</p>
              </div>
              <div className="innerproduct career-top">
                <div className="career-info-div">
                  <Form
                    fields={this.state.fields}
                    onChange={this.fieldChange}
                    onValid={this.handleFormSubmit}
                    outletListData={this.getOutletData}
                    onInvalid={() => console.log("Form invalid!")}/>
                </div>
              </div>
            </div>

          </div>
        </div>

        


        <Footer />

        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const phonenumberPattern = /^[0-9]{6,14}$/;

const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "please enter valid Phone number.";

const isEmpty = (value) => (value === "" ? "This field is required." : null);

const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";

function validationConfig(props) {
  const { name, mobile, email, message, subject } = props.fields;

  return {
    fields: ["name", "mobile", "email", "message", "subject"],

    validations: {
      name: [[isEmpty, name]],
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
      ],
      email: [
        [isEmpty, email],
        [isEmail, email],
      ],
      subject: [[isEmpty, subject]],
      message: [[isEmpty, message]],
    },
  };
}

class Form extends React.Component {
  state = {
    pageTitle: "",
    pageDesc: "",
  };

  render() {
    const {
      fields,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;
    let errMsgFirstName, errMsgEmail, errMsgMobile, errMsgType, errMsgMessage;

    let contactcontent = "";

    if (this.props.contactContent != undefined) {
      contactcontent = Parser(
        this.props.contactContent.result_set[0].staticblocks_description
      );
    }

    if ($validation.name.error.reason !== undefined) {
      document.getElementsByClassName("name").className = "error";
      errMsgFirstName = $validation.name.show && (
        <span className="error">{$validation.name.error.reason}</span>
      );
    }

    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }

    if ($validation.subject.error.reason !== undefined) {
      errMsgType = $validation.subject.show && (
        <span className="error">{$validation.subject.error.reason}</span>
      );
    }

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }

    if ($validation.message.error.reason !== undefined) {
      errMsgMessage = $validation.message.show && (
        <span className="error">{$validation.message.error.reason}</span>
      );
    }

    return (
      <div className="white_bgbx">
        {/* <div className="locate-map">          
            <div className="locate-mapleft">
              <div className="locate-list">
                {this.props.outletListData()} 
              </div>
            </div>
            <div className="locate-mapright">
              <div id="locateGmap">
                {this.props.contMapFun()}
              </div>
            </div>
        </div> */}
        <div className="contact_form">
          <h3>DROP US A LINE</h3>
          <form className="form_sec clear">
            <div className="contact_col">
              <div className="form-group">
                <div className="focus-out">
                  <label>Name*</label>
                  <input
                    type="input"
                    className="form-control input-focus"
                    value={fields.name}
                    maxLength="80"
                    {...$field("name", (e) => onChange("name", e.target.value))}
                  />
                  {errMsgFirstName}
                </div>
              </div>
              <div className="form-group">
                <div className="focus-out">
                  <label>Contact Number*</label>
                  <input
                    type="text"
                    className="form-control input-focus"
                    value={fields.mobile}
                    maxLength="11"
                    {...$field("mobile", (e) =>
                      onChange("mobile", e.target.value)
                    )}
                  />
                  {errMsgMobile}
                </div>
              </div>
              <div className="form-group">
                <div className="focus-out">
                  <label>Email*</label>
                  <input
                    type="input"
                    className="form-control input-focus"
                    value={fields.email}
                    maxLength="85"
                    {...$field("email", (e) =>
                      onChange("email", e.target.value)
                    )}
                  />
                  {errMsgEmail}
                </div>
              </div>
              <div className="form-group">
                <div className="focus-out">
                  <label>Order Number</label>
                  <input
                    type="text"
                    className="form-control input-focus"
                    value={fields.order_number}
                    {...$field("order_number", (e) =>
                      onChange("order_number", e.target.value)
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
                <div className="re_select">
                  <select className="form-control select-gender" {...$field('subject', (e) => onChange('subject', e.target.value)) }>
                    <option value=""> Subject* </option>
                    <option value="Catering" id="general-enquiry"> Catering </option>
                    <option value="Events" id="order-enquiry"> Events </option>
                    <option value="General Enquiry" id="others"> General Enquiry  </option>
                    <option value="Careers" id="order-enquiry"> Careers  </option>
                    <option value="Leave Feedback" id="order-enquiry"> Leave Feedback </option>
                  </select>
                  {errMsgType}
                </div>
              </div>
            <div className="contact_col">
              <div className="form-group">
                <div className="focus-out">
                  <label>Message*</label>

                  <textarea
                    className="form-control input-focus"
                    id="feedback"
                    {...$field("message", (e) =>
                      onChange("message", e.target.value)
                    )}
                  ></textarea>
                  {errMsgMessage}
                </div>
              </div>
              <div className="btn_sec">
                <button
                  type="button"
                  className="btn btn_black btn-lg btn-block"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.$submit(onValid, onInvalid);
                  }}
                >
                  Submit<div className="ripple-container"></div>
                </button>
                <br />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
Form = validated(validationConfig)(Form);

const mapStateToProps = (state) => {
  return {
    outlets: state.outlets,
    globalsettings: state.settings,
    contactdata: state.contactdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

Events.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Events)
);
