/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId, apiUrl, timThumpUrl   } from "../Helpers/Config";
var Parser = require("html-react-parser");
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { connect } from "react-redux";
import innerBanner from "../../common/images/inner-banner.jpg";
import menuImg from "../../common/images/press1.jpg";

import menuImgtwo from "../../common/images/press2.jpg";

import menuImgthree from "../../common/images/press3.jpg";
import { callImage } from "../Helpers/SettingHelper";

import Slider from "react-slick";
const settings = {
  infinite: true,
  speed: 2000,
  margin: 10,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  dots: false,
  arrows: true,
  responsive: [
  {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const settingsAwards = {
  infinite: true,
  speed: 2000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  dots: true,
  arrows: false,
};

import {
  GET_PICKUP_OUTLETS,
  GET_CONTACTDATA,
  GET_CONTACTCONTENT,
  GET_GLOBAL_SETTINGS,
  GET_WHATS_HAPPENING,
} from "../../actions";

var Parser = require("html-react-parser");

class Press extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whatshappening:[],
      whatshappeningpath:[],
      whatshappenings_source:'', 
      whatshappenings:[],
    }
    this.props.getWhatsHappening();
  }

  listwhatshappenings() {

    let whatshappenings = this.state.whatshappenings;
    let whatshappenings_source = this.state.whatshappenings_source;

    if(Object.keys(whatshappenings).length > 0) {
        const mainMenu = whatshappenings.map((loaddata, index) => {

            if ((loaddata.gallery_image).length <= 4) {
                var infinite = false;
            } else {
                var infinite = true;
            }

            if(index > 0){
                var awardsclass = 'awards-section';
            }else{
                var awardsclass = '';
            }
          
            return (

              <div className={"menu-blckdiv press-body-div "+awardsclass}  key={'wh'+(index+1)}>
                <div className="container">
                  {index <=1 &&
                  <div className="menu-blck-heading">
                    <h3>{loaddata.wh_name}</h3>
                  </div>
                  } 

                  <div className="innerproduct press-slider-top">
                    {index == 0 &&
                      <Slider {...settings}>
                      
                        {loaddata.gallery_image.map((loaddatagall, gallindex) => {
                          
                        return(
                           <li class="products-single-li" key={'whgall'+index+''+(gallindex+1)} onClick={() => this.setState({photoIndex:whatshappenings_source+"/"+loaddatagall.gallery_image, isOpen: true })}>
                            <div class="products-container-div">
                              <a href={loaddatagall.gallery_link} target="_blank">
                                <div class="products-image-div products-image-div-arrow">
                                  {callImage(whatshappenings_source, loaddatagall.gallery_image, 450, 450, timThumpUrl)}
                                  <p>{stripslashes(loaddatagall.gallery_desc)}</p>
                                </div>
                                <div className="press-tag">
                                  {/*<span>{stripslashes(loaddatagall.gallery_title)}</span>*/}
                                </div>
                              </a>
                            </div>
                          </li>

                        );
                        
                      })}

                      </Slider>
                    }

                    {index > 0 &&
                      <Slider {...settingsAwards}>
                      
                        {loaddata.gallery_image.map((loaddatagall, gallindex) => {
                          
                        return(
                           <li class="products-single-li" key={'whgall'+index+''+(gallindex+1)} onClick={() => this.setState({photoIndex:whatshappenings_source+"/"+loaddatagall.gallery_image, isOpen: true })}>
                            <div class="products-container-div">
                                <div class="products-image-div">
                                  {callImage(whatshappenings_source, loaddatagall.gallery_image, 450, 450, timThumpUrl)}
                                  <p>{stripslashes(loaddatagall.gallery_desc)}</p>
                                </div>
                                <div className="press-tag">
                                  {/*<span>{stripslashes(loaddatagall.gallery_title)}</span>*/}
                                </div>
                            </div>
                          </li>

                        );
                        
                      })}

                      </Slider>
                    }

                  </div>
                </div>
              </div>

              );
          
        });

      return mainMenu;
    } else {
      return null;
    }
  }

  componentDidMount() {

      axios.get(apiUrl + 'cms/whats_happening?app_id='+appId).then(res => {

        if (res.data.status === "ok") {

          let whatshappenings_source = res.data.common.image_source;
            let whatshappenings = res.data.result_set;

          this.setState({whatshappenings_source:whatshappenings_source, whatshappenings:whatshappenings })

        } else if (res.data.status === "error") {

        }

        return false;

      });

  }

  componentWillReceiveProps(PropsData) {
    
    if (PropsData.whatshappening !== undefined && PropsData.whatshappening !== this.state.whatshappening) {

      this.setState({
        whatshappening: PropsData.whatshappening,
        whatshappeningpath: PropsData.whatshappeningpath, 
      });

    }
  }

  render() {
    return (
      <div className="menumain-div press-maindiv">
        <Header />
              {this.listwhatshappenings()}

        <Footer />

        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  var whatshappening = Array(); 
  var whatshappeningpath = Array();

  if (Object.keys(state.whatshappening).length > 0) {
    whatshappening  = state.whatshappening[0].result_set;    
    whatshappeningpath = state.whatshappening[0].common;    
  }

  return {
    outlets: state.outlets,
    globalsettings: state.settings,
    contactdata: state.contactdata,
    whatshappening: whatshappening,
    whatshappeningpath:whatshappeningpath, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getWhatsHappening: () => {
      dispatch({ type: GET_WHATS_HAPPENING });
    },
  };
};

Press.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Press)
);
