/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId } from "../Helpers/Config";
var Parser = require("html-react-parser");
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { connect } from "react-redux";
import innerBanner from "../../common/images/inner-banner.jpg";
import menuImg from "../../common/images/menu-all.jpg";

import {
  GET_PICKUP_OUTLETS,
  GET_CONTACTDATA,
  GET_CONTACTCONTENT,
  GET_GLOBAL_SETTINGS,
} from "../../actions";

var Parser = require("html-react-parser");

class Menu extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="menumain-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <img src={innerBanner} />
              <div className="inner-banner-content">
                <p>menu</p>
              </div>
          </div>
        </div>

        <div className="menu-blckdiv">
          <div className="container">
            <div className="menu-blck-heading">
              <h3>restaurant menu</h3>
              <p>delightful experience</p>
            </div>

            <div className="innerproduct">
              <ul class="products-list-ulmain">
                <li class="products-single-li">
                  <div class="products-container-div">
                      <a href="" target="_blank">
                    <div class="products-image-div">
                      <img src={menuImg} />
                      <p>This is sample image, we can replace this at the development stage please confirm the layout of 
                      this section</p>
                    </div>
                      </a>
                  </div>
                </li>

                <li class="products-single-li">
                  <div class="products-container-div">
                      <a href="" target="_blank">
                    <div class="products-image-div">
                      <img src={menuImg} />
                      <p>This is sample image, we can replace this at the development stage please confirm the layout of 
                      this section</p>
                    </div>
                      </a>
                  </div>
                </li>

                <li class="products-single-li">
                  <div class="products-container-div">
                      <a href="" target="_blank">
                    <div class="products-image-div">
                      <img src={menuImg} />
                      <p>This is sample image, we can replace this at the development stage please confirm the layout of 
                      this section</p>
                    </div>
                      </a>
                  </div>
                </li>

                <li class="products-single-li">
                  <div class="products-container-div">
                      <a href="" target="_blank">
                    <div class="products-image-div">
                      <img src={menuImg} />
                      <p>This is sample image, we can replace this at the development stage please confirm the layout of 
                      this section</p>
                    </div>
                      </a>
                  </div>
                </li>

              </ul>

              <div class="product-info-div">
                <div class="products-ordernow-action">
                  <a class="button" href="/reservation">reserve now</a>
                </div>
              </div>

            </div>
          </div>
        </div>


        <Footer />

        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outlets: state.outlets,
    globalsettings: state.settings,
    contactdata: state.contactdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

Menu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Menu)
);
