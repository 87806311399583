/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  timThumpUrl,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  callImage,
  showLoader,
  hideLoader,
  showCustomAlert,
  showCartLst,
  showAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";
import heartWhite from "../../common/images/heart-white.png";
import heartYellow from "../../common/images/heart-yellow.png";
import smartPhone from "../../common/images/smart-phone.png";
import proVouchar from "../../common/images/pro-vouchar.png";
import cashVouchar from "../../common/images/cash-vouchar.png";
import { GET_PRODUCT_LIST } from "../../actions";
import { setMinutes, setHours, getDay, format } from "date-fns";
var Parser = require("html-react-parser");
var qs = require("qs");

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      productDetails: [],
      filter_Tag: [],
      voucher_mobile: "",
      voucher_message: "",
      voucher_name: "",
      sort_By_Price: "",
      sort_By_PriceName: "Sort By",
      sort_By_category: "",
      sort_By_categoryName: "Shop By",
      showShortByPrice: false,
      showShortByCategory: false,
      isOpen: false, 
      popUpImages: [],
    };
  }

  componentDidMount() {
    var currentthis = this;
    $(document).click(function (e) {
      if (!$(e.target).is(".filters-lhs, .filters-lhs * ")) {
        currentthis.setState({ showShortByPrice: false });
      }
      if (!$(e.target).is(".filters-rhs, .filters-rhs * ")) {
        currentthis.setState({ showShortByCategory: false });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    if (this.state.searchKeyWord !== nextProps.productState.searchKeyWord) {
      if (nextProps.productState.searchKeyWord !== "") {
        this.setState(
          { searchKeyWord: nextProps.productState.searchKeyWord },
          function () {
            showLoader("productlist-main-div", "class");
            this.productsubcatlist();
          }
        );
      }
    }
    if (this.state.emptysearchKey !== nextProps.productState.emptysearchKey) {
      this.setState(
        {
          emptysearchKey: nextProps.productState.emptysearchKey,
          searchKeyWord: "",
        },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("emptysearchKey", 0);
          this.setState({ emptysearchKey: 0 });
        }
      );
    }

    if (nextProps.productState.stopFilterPrice === "Yes") {
      this.sortByPrice(nextProps.productState.sort_By_Price);
      this.props.sateValChange("stopFilterPrice", "No");
    }
    if (nextProps.productState.startFilter === "Yes") {
      this.setState(
        {
          showShortByPrice: false,
          showShortByCategory: false,
          sort_By_Price: "",
          sort_By_PriceName: "Sort By",
          sort_By_category: "",
          sort_By_categoryName: "Shop By",
        },
        function () {
          this.props.sateValChange("startFilter", "No");
        }
      );
    }
    if (selectedNavigation !== this.state.selectedNavigation) {
      $('html, body').animate({scrollTop: $(".productlist-main-div").offset().top - 30 }, 2000);
      var catSlug = "";
      var subcatSlug = "";
      if (selectedNavigation !== "") {
        catSlug = selectedNavigation;
        subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        this.props.getProductList(catSlug, subcatSlug, "");
        $(".addcart_done_maindiv").hide();
        $(".smiple_product_lk").show();
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        selectedslugType: slugType,
        catSlug: catSlug,
        subcatSlug: subcatSlug,
      });
    }
    if (nextProps.productState.setFiltetTag === "Yes") {
      this.setState(
        { filter_Tag: nextProps.productState.filterTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetTag", "No");
        }
      );
    }
    if (nextProps.productState.setFiltetBrandTag === "Yes") {
      this.setState(
        { filter_Brand_Tag: nextProps.productState.filterBrandTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetBrandTag", "No");
        }
      );
    }

    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist, loading: false },
        function () {
          this.productsubcatlist();
        }
      );
    }
    if (this.state.cartItemsLength !== nextProps.productState.cartItemsLength) {
      this.productsubcatlist();
    }
  }

  addToCartVoucherMe() {
    showLoader("add_to_cart", "class");
    var availabilityId = cookie.load("defaultAvilablityId");
    /*var availabilityId = deliveryId;*/
    var availabilityName =
      availabilityId === deliveryId ? "Delivery" : "Pickup";
    var isAddonProduct = "No";
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");

    var TotalPrice =
      this.state.voucherProductDetail.product_price *
      this.state.voucher_product_qty;
    var postObject = {};
    postObject = {
      app_id: appId,
      product_id: this.state.voucherProductDetail.product_id,
      product_qty: this.state.voucher_product_qty,
      product_name: this.state.voucherProductDetail.product_name,
      product_sku: this.state.voucherProductDetail.product_sku,
      product_total_price: TotalPrice,
      product_unit_price: this.state.voucherProductDetail.product_price,
      product_type: 5,
      availability_id: availabilityId,
      availability_name: availabilityName,
      isAddonProduct: isAddonProduct,
      reference_id: customerId === "" ? getReferenceID() : "",
      customer_id: customerId,
      voucher_gift_name:
        typeof cookie.load("UserFname") === "undefined"
          ? ""
          : cookie.load("UserFname"),
      voucher_gift_mobile:
        typeof cookie.load("UserMobile") === "undefined"
          ? ""
          : cookie.load("UserMobile"),
      voucher_gift_email:
        typeof cookie.load("UserEmail") === "undefined"
          ? ""
          : cookie.load("UserEmail"),
      voucher_gift_message: "",
      voucher_for: '',
      product_voucher_expiry_date:
        this.state.voucherProductDetail.product_voucher_expiry_date !== ""
          ? this.state.voucherProductDetail.product_voucher_expiry_date
          : "",
    };

    Axios.post(apiUrl + "cartnew/insert", qs.stringify(postObject)).then((res) => {
      $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
      hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".smiple_product_lk")
        .show();
      if (res.data.status === "ok") {
        hideLoader("add_to_cart", "class");
        this.props.sateValChange("cartflg", "yes");
        removePromoCkValue();
        showCustomAlert("success", "Great choice! Item added to your cart.");
        /*showCartLst();*/
        this.handleShowAlertFun(
          "success",
          "Great choice! Item added to your cart."
        );
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
        });
      } else if (res.data.status === "error") {
        hideLoader("add_to_cart", "class");
        var errMsgtxt =
          res.data.message !== ""
            ? res.data.message
            : "Sorry! Products can`t add your cart.";
        showCustomAlert("error", errMsgtxt);
        this.handleShowAlertFun("Error", errMsgtxt);
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
          voucherIndexFlag: "",
        });
      }

      return false;
    });
  }

  addToCartVoucher() {
    var error = 0;
    if (this.state.voucher_name === null || this.state.voucher_name === "") {
      error++;
      this.setState({
        error_voucher_name: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_name: "" });
    }

    const phonenumberPattern = /^[0-9]{6,14}$/;
    if (this.state.voucher_mobile != null &&
      this.state.voucher_mobile != "" && !this.state.voucher_mobile.match(phonenumberPattern)) {
      error++;
      this.setState({
        error_voucher_mobile: Parser(
          '<span class="error">Please enter valid Mobile Number..</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_mobile: "" });
    }
    console.log(this.state.voucher_mobile)
    if (
      this.state.voucher_mobile === null ||
      this.state.voucher_mobile === ""
    ) {
      error++;
      this.setState({
        error_voucher_mobile: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_mobile: "" });
    }


    if (this.state.voucher_email === null || this.state.voucher_email === "") {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        this.state.voucher_email
      )
    ) {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This is not a valid email.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_email: "" });
    }

    if (
      this.state.voucher_message === null ||
      this.state.voucher_message === ""
    ) {
      error++;
      this.setState({
        error_voucher_message: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_message: "" });
    }

    if (error == 0) {
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");

      var TotalPrice =
        this.state.voucherProductDetail.product_price *
        this.state.voucher_product_qty;

      var ImagePath = this.state.voucherProductDetail.product_thumbnail;
      if (ImagePath !== "") {
        var postImagePath =
          this.state.productimagePath +
          this.state.voucherProductDetail.product_thumbnail;
      } else {
        postImagePath = "";
      }

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: this.state.voucherProductDetail.product_id,
        product_qty: this.state.voucher_product_qty,
        product_name: this.state.voucherProductDetail.product_name,
        product_sku: this.state.voucherProductDetail.product_sku,
        product_total_price: TotalPrice,
        product_unit_price: this.state.voucherProductDetail.product_price,
        product_image: postImagePath,
        product_type: 5,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        voucher_gift_name:
          this.state.voucher_name !== ""
            ? this.state.voucher_name
            : cookie.load("UserFname"),
        voucher_gift_mobile:
          this.state.voucher_mobile !== "" ? this.state.voucher_mobile : "",
        voucher_gift_email:
          this.state.voucher_email !== ""
            ? this.state.voucher_email
            : cookie.load("UserEmail"),
        voucher_gift_message:
          this.state.voucher_message !== "" ? this.state.voucher_message : "",
        voucher_for: 'Gift',
        product_voucher_expiry_date:
          this.state.voucherProductDetail.product_voucher_expiry_date !== ""
            ? this.state.voucherProductDetail.product_voucher_expiry_date
            : "",
      };
      showLoader("add_to_cart", "class");

      Axios.post(apiUrl + "cartnew/insert", qs.stringify(postObject)).then(
        (res) => {
          $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
          hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            hideLoader("add_to_cart", "class");
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            /*showCartLst();*/
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
              voucher_name: "",
              voucher_mobile: "",
              voucher_email: "",
              voucher_message: "",
            });
          } else if (res.data.status === "error") {
            hideLoader("add_to_cart", "class");
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
              voucherIndexFlag: "",
            });
          }

          return false;
        }
      );
    }
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value });
    this.setState({ ["error_" + item]: "" });
  };

  sortByPrice(sort, event) {
    if (
      event !== "" &&
      typeof event !== undefined &&
      typeof event !== "undefined"
    ) {
      event.preventDefault();
    }

    var sort_By_PriceName = "";
    if (sort === "ASC") {
      sort_By_PriceName = "Price Low to High";
    } else {
      sort_By_PriceName = "Price High to Low";
    }
    this.setState({
      sort_By_Price: sort,
      sort_By_PriceName: sort_By_PriceName,
    });
    showLoader("productlist-main-div", "class");
    this.props.getProductList(
      this.state.catSlug,
      this.state.subcatSlug,
      "",
      "product_price",
      sort
    );
  }
  sortByCategories(categorySlug, categoryName, event) {
    event.preventDefault();
      var link = "/orders/category/"+categorySlug;
      this.props.history.push(link);
    
    showLoader("productlist-main-div", "class");
    this.setState(
      {
        sort_By_category: categorySlug,
        sort_By_categoryName: categoryName,
      },
      function () {
        this.props.sateValChange("categorySlug", categorySlug);
      }
    );
    var sortKey = "";
    var sortValue = "";
    if (this.state.sort_By_Price !== "") {
      sortKey = "product_price";
      sortValue = this.state.sort_By_Price;
    }
    this.props.getProductList(categorySlug, "", "", sortKey, sortValue);
  }

  showSortBy(type) {
    if (type === "price") {
      if (this.state.showShortByPrice === true) {
        this.setState({ showShortByPrice: false });
      } else {
        this.setState({ showShortByPrice: true, showShortByCategory: false });
      }
    } else if (type === "category") {
      if (this.state.showShortByCategory === true) {
        this.setState({ showShortByCategory: false });
      } else {
        this.setState({ showShortByCategory: true, showShortByPrice: false });
      }
    }
  }


  productsubcatlist() {
    var subcatlistArr = this.props.productSubcatlist;
    if (Object.keys(subcatlistArr).length > 0) {
      hideLoader("productlist-main-div", "class");
      const productDetails = subcatlistArr.map((categories, categoryIndex) => {
        if (categories.products.length > 0) {
          return (
            <div className="innerproduct" key={categoryIndex} id={"pro_cat_" + categories.pro_subcate_slug} >
              {categoryIndex === 0 && (
                <div className="header-box mb-40 catry-top-">
                  {/*<h2 id={categories.pro_cate_slug}>
                    {stripslashes(categories.pro_cate_name)}
                  </h2>*/}
                  {categories.pro_cate_description !== "" && (
                    <p>{stripslashes(categories.pro_cate_description)}</p>
                  )}
                </div>
              )}

              <h3 className="subcat-title" id={categories.pro_subcate_slug}>
                {stripslashes(categories.pro_subcate_name)}
              </h3>
              <ul className="products-list-ulmain">
                {this.productList(
                  categories.products,
                  categories.products.length,
                  categories.pro_subcate_slug
                )}
              </ul>
            </div>
          );
        }
      });
      this.setState({ productDetails: productDetails });
      return productDetails;
    } else {
      return "";
    }
  }

  chkProStockCls(proSlug, Stock, offer) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }
    if(offer != '' && offer != null){
      returnText = 'offer ' + returnText;
    }

    return returnText;
  }

  getProductSplPrice(productDetail) {
    var productSpecialPrice =
      productDetail.product_special_price !== ""
        ? parseFloat(productDetail.product_special_price)
        : 0;
    if (
      productSpecialPrice > 0 &&
      productDetail.product_special_price_from_date !== "" &&
      productDetail.product_special_price_to_date !== ""
    ) {
      var datetime = new Date();
      var crtDatefrmtTxt = format(datetime, "yyyy-MM-dd");
      var crtDatefrmtObj = new Date(crtDatefrmtTxt);
      var proSplFromdate = new Date(
        productDetail.product_special_price_from_date
      );
      var proSplTodate = new Date(productDetail.product_special_price_to_date);
      if (proSplFromdate <= crtDatefrmtObj && proSplTodate >= crtDatefrmtObj) {
        return (
          <>
          <div className="product-spl-price">
            <h3><sup>$</sup>{Parser((productDetail.product_price))}</h3>
          </div>
          <div className="product-price">
              <h3>${(productSpecialPrice)}</h3>
          </div>
          </>
        );
      }else{
        return (
            <div className="product-price">
              <h3>${(productDetail.product_price)}</h3>
            </div>
        );
      }
    }else{
        return (
            <div className="product-price">
              <h3>${(productDetail.product_price)}</h3>
            </div>
        );
      }
  }

  getFavActivCls(product) {
    var favActCls = "disbl_href_action favour-icon-link";
    if (product.fav_product_primary_id === product.product_primary_id) {
      favActCls = favActCls + " active";
    }

    return favActCls;
  }

  favourActFun(productDetail, event) {
    event.preventDefault();
    var productPrimaryId = productDetail.product_primary_id;

    var actionFlgText = "Yes";
    if (
      productDetail.fav_product_primary_id === productDetail.product_primary_id
    ) {
      actionFlgText = "No";
    }

    var postObject = {};
    postObject = {
      app_id: appId,
      outlet_id: cookie.load("orderOutletId"),
      product_id: productPrimaryId,
      fav_flag: actionFlgText,
      availability_id: cookie.load("defaultAvilablityId"),
      customer_id: cookie.load("UserId"),
    };

    var succesFlgTxt =
      actionFlgText === "Yes"
        ? "Great choice! Item added to your favourite list."
        : "Item was removed to your favourite list.";
    var errorFlgTxt =
      actionFlgText === "Yes"
        ? "Sorry! Products can`t add your favourite list."
        : "Sorry! Products can`t remove your favourite list.";

    Axios.post(
      apiUrl + "products/favourite_products_add",
      qs.stringify(postObject)
    ).then((res) => {
      if (res.data.status === "ok") {
        this.handleShowAlertFun("success", succesFlgTxt);
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== "" ? res.data.message : errorFlgTxt;
        this.handleShowAlertFun("Error", errMsgtxt);
      }
      this.props.sateValChange("favTgr", "Yes");
      return false;
    });
  }

  /* Products List */
  productList(list, productcount, pro_subcate_slug) {
    var imageSource = this.props.productCommon.product_image_source;
    var galleryImageSource =
      this.props.productCommon.product_gallery_image_source;
    var tagImageSource = this.props.productCommon.tag_image_source;
    var slugType = this.state.selectedslugType;
    var naviSlug = this.state.selectedNavigation;
    var totalProductShow = 0;
    var listProduct = list.map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";
      var popUpImage = [];
      if (productDetail.image_gallery.length > 0) {
        productDetail.image_gallery.map((ImageName, index) => {
          popUpImage.push(
            galleryImageSource + "/" + ImageName.pro_gallery_image
          );
        });
      } else {
        popUpImage.push(imageSource + "/" + productDetail.product_thumbnail);
      }

      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      let filter = this.checkFilterTag(productDetail.product_tag);
      if (filter === 1) {
        totalProductShow++;
        return (
        
          <li
            className={this.chkProStockCls(
              productDetail.product_slug,
              productDetail.product_stock,
              productDetail.product_tag_info
            )}
            id={prodivId}
            key={index}
          >
          <div className="products-container-div">

            {productDetail.product_tag_info != '' && productDetail.product_tag_info != null &&
              <div className="sale-flag-sash">
                <span className="sale-text">{productDetail.product_tag_info}</span>
              </div>
            }
            <div className="products-image-div"                 onClick={() =>
                  this.setState({
                    popUpImage: popUpImage,
                    photoIndex: 0,
                    isOpen: true,
                  })
                } >
              {cookie.load("UserId") && (
                <a
                  className={this.getFavActivCls(productDetail)}
                  href="/"
                  onClick={this.favourActFun.bind(this, productDetail)}
                >
                  <img
                    className="disbl_href_action favour-heartwhite-icon"
                    src={heartWhite}
                  />
                  <img
                    className="disbl_href_action favour-heartyellow-icon"
                    src={heartYellow}
                  />
                </a>
              )}
              {productDetail.product_thumbnail !== "" ? (
                <img
                  src={imageSource + "/" + productDetail.product_thumbnail}
                />
              ) : (
                <img src={noimage} />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h5>
                    {productDetail.product_alias !== ""
                      ? stripslashes(productDetail.product_alias)
                      : stripslashes(productDetail.product_name)}
                  </h5>
                </div>
                <div className="product-tag-list">
                  {Object.keys(productDetail.product_tag).length > 0 ? (
                    <ul>
                      {productDetail.product_tag.map((producttag, index1) => {
                        return (
                          <li key={index1}>
                            {producttag.pro_tag_name !== ""
                              ? producttag.pro_tag_name
                              : ""}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="product-short-description">
                <p>{description !== "" ? description : Parser("&nbsp;")}</p>
              </div>

              {this.getProductSplPrice(productDetail)}


              <div className="products-ordernow-action">
                {productDetail.pro_current_datetime_avl === "yes" ? (
                  productDetail.product_stock > 0 ||
                  productDetail.product_stock === null ? (
                    productDetail.product_type === "1" ||
                    productDetail.product_type === "5" ? (
                      <a
                        className="button order_nowdiv smiple_product_lk disbl_href_action"
                        href="/"
                        onClick={this.addToCartSimple.bind(
                          this,
                          productDetail,
                          "initial"
                        )}
                      >
                        Add to Cart
                      </a>
                    ) : (
                      <a
                        href="/"
                        onClick={this.viewProDetail.bind(this, productDetail)}
                        title="Product Details"
                        id={comboProId}
                        className="button order_nowdiv compo_product_lk"
                      >
                        Order Now
                      </a>
                    )
                  ) : (
                    <a
                      className="button order_nowdiv disabled disbl_href_action"
                      href="/"
                    >
                      Sold Out
                    </a>
                  )
                ) : (
                  <a
                    className="button order_nowdiv disabled disbl_href_action"
                    href="/"
                  >
                    Sold Out
                  </a>
                )}

                <div className="addcart_row addcart_done_maindiv">
                  <div className="addcart-row-child">
                    <div className="qty_bx">
                      <span
                        className="qty_minus"
                        onClick={this.proQtyAction.bind(
                          this,
                          productDetail.product_primary_id,
                          "decr"
                        )}
                      >
                        -
                      </span>
                      <input
                        type="text"
                        className="proqty_input"
                        readOnly
                        value="1"
                      />
                      <span
                        className="qty_plus product_plus"
                        onClick={this.proQtyAction.bind(
                          this,
                          productDetail.product_primary_id,
                          "incr"
                        )}
                      >
                        +
                      </span>
                    </div>
                    <button
                      className="btn btn_black"
                      onClick={this.addToCartSimple.bind(
                        this,
                        productDetail,
                        "done"
                      )}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>

            </div>

          </li>
        );
      }
    });
    if (totalProductShow > 0) {
      $("#" + pro_subcate_slug).show();
      return listProduct;
    } else {
      $("#" + pro_subcate_slug).hide();
      listProduct = (
        <div>
          <h3>No Product Found</h3>
        </div>
      );
      return listProduct;
    }
  }

  checkFilterTag(productFilter) {
    var current = this;
    if (
      this.state.filter_Tag !== "" &&
      typeof this.state.filter_Tag !== undefined &&
      typeof this.state.filter_Tag !== "undefined" &&
      this.state.filter_Tag.length > 0
    ) {
      if (Object.keys(productFilter).length > 0) {
        let tagAvil = 0;
        productFilter.map(function (item) {
          if (current.state.filter_Tag.indexOf(item.tag_id) >= 0) {
            tagAvil++;
          }
        });
        if (tagAvil > 0) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 1;
    }
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

      var orderOutletId = cookie.load("orderOutletId");
      if (orderOutletId === "" || orderOutletId === undefined){
        $.magnificPopup.open({
          items: {
            src: "#order-popup",
          },
          type: "inline",
        });
        return false;
      }

    if (productDetail.product_type === "5") {
      var IndexFlg = productDetail.product_primary_id;
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      $.magnificPopup.open({
        items: {
          src: "#vouchergift-popup",
        },
        type: "inline",
      });

      this.setState({
        voucherProductDetail: productDetail,
        voucher_product_qty: proqtyQty,
        voucherIndexFlag: IndexFlg,
      });
    } else {

      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
        return false;
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        /*var availabilityId = deliveryId;*/
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.setState({compoinput_value: 1});
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            // showCustomAlert("success", "Great choice! Item added to your cart.");
            /*showCartLst();*/
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
          } else if (res.data.status === "error") {
            this.setState({compoinput_value: 1});
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
          }

          return false;
        });
      }
    }
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();

    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined){
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
      return false;
    }
    
    this.setState({ product_remarks: "" }, () => 
    console.log(this.state.product_remarks));
    setTimeout(function(){
    $(document).find('#special_notes').val('');
    },1000)
    var productSlug = productDetail.product_slug;
    if (productSlug !== "") {
      $("#proIndex-" + productDetail.product_primary_id).addClass("active");
      this.props.sateValChange("view_pro_data", productSlug);
    }
    return false;
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  render() {
    const { isOpen, photoIndex, popUpImage } = this.state;
    return (
      <>
      <div className="productlist-main-div">
        <div className="innerproduct_row">
                <div className="header-box mb-40 catry-top-title new">

                  <div className="cafe-lhs-filter filter_by_brand_tag">
                    <span className="filter-label filters-lhs">Sort By Price</span>
                      <span
                        className="filter-act"
                        onClick={this.showSortBy.bind(this, "price")}
                      >
                        {this.state.sort_By_PriceName}
                      </span>
                      <ul
                        className={
                          this.state.showShortByPrice === true ? "active" : ""
                        }
                      >
                        <li
                          className={
                            this.state.sort_By_Price === "ASC" ? "active" : ""
                          }
                        >
                          <a href="#" onClick={this.sortByPrice.bind(this, "ASC")}>
                            Price Low to High
                          </a>
                        </li>
                        <li
                          className={
                            this.state.sort_By_Price === "DESC" ? "active" : ""
                          }
                        >
                          <a href="#" onClick={this.sortByPrice.bind(this, "DESC")}>
                            Price High to Low
                          </a>
                        </li>
                      </ul>
                  </div>

                  <div className="cafe-lhs-filter filter_by_tag">
                    <span className="filter-label filters-rhs">Shop By category</span>
                      <span
                      className="filter-act"
                      onClick={this.showSortBy.bind(this, "category")}
                        >
                      {this.state.sort_By_categoryName}
                    </span>
                        {this.props.navigateMenu.length > 0 && (
                          <ul
                            className={
                              this.state.showShortByCategory === true ? "active" : ""
                            }
                          >
                            {this.props.navigateMenu.map((item, index) => {
                                return (
                                  <li
                                    key={index}
                                    className={
                                      this.state.sort_By_category === item.pro_cate_slug
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <a
                                      href="#"
                                      onClick={this.sortByCategories.bind(
                                        this,
                                        item.pro_cate_slug,
                                        item.menu_custom_title
                                      )}
                                    >
                                      {item.menu_custom_title}
                                    </a>
                                  </li>
                                );
                            })}
                          </ul>
                        )}
                  </div>
                </div>

        {this.state.productDetails}

        </div>
        <div id="vouchergift-popup"className="white-popup mfp-hide popup_sec vouchergift-popup"> 
          <div className="voucher-popup-inner pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>IM PURCHASING THIS</h3>
            </div>
            <div className="full-login-new-body">
              <div className="popup-footer voucher-popup-body">
                <Tabs>
                  <TabList>
                    <Tab>For Gift</Tab>
                    <Tab>For Me</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="voucher-popup-inner">
                      <h2>GIFT RECEIVER'S INFORMATION</h2>
                      <div className="form-group">
                        <div className="">
                          <label>Name</label>
                          <input
                            type="text"
                            id="guest-name"
                            name="guest-name"
                            value={this.state.voucher_name}
                            onChange={this.handleChangeTxt.bind(
                              this,
                              "voucher_name"
                            )}
                            className="form-control input-focus"
                          />
                          {this.state.error_voucher_name}
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="">
                          <label>Enter Mobile Number</label>
                          <input
                            type="tel"
                            id="guest-number"
                            name="guest-number"
                            value={this.state.voucher_mobile}
                            onChange={this.handleChangeTxt.bind(
                              this,
                              "voucher_mobile"
                            )}
                            className="form-control input-focus"
                          />
                          {this.state.error_voucher_mobile}
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="">
                          <label>Email Address</label>
                          <input
                            type="text"
                            id="guest-email"
                            name="guest-email"
                            value={this.state.voucher_email}
                            onChange={this.handleChangeTxt.bind(
                              this,
                              "voucher_email"
                            )}
                            className="form-control input-focus"
                          />
                          {this.state.error_voucher_email}
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="">
                          <label>Message</label>
                          <textarea
                            type="text"
                            id="guest-message"
                            name="guest-message"
                            value={this.state.voucher_message}
                            onChange={this.handleChangeTxt.bind(
                              this,
                              "voucher_message"
                            )}
                            className="form-control input-focus"
                          />
                          {this.state.error_voucher_message}
                        </div>
                      </div>

                      <div className="howtouse-gift-vouchar">
                        <img src={smartPhone} />
                        <h3>How to use</h3>
                        <p>
                          After purchase your recipient can get email
                          notificationand they can use above email address to
                          sign up. Vouchers will be available within their my
                          account{" "}
                        </p>
                      </div>
                      <div className="form-group gift-group">
                        <button
                          type="button"
                          className="button add_to_cart"
                          onClick={this.addToCartVoucher.bind(this)}
                        >
                          Add To Cart
                        </button>
                      </div> 
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="voucher-popup-inner">
                      <div className="howtouse-gift-vouchar">
                        <img src={smartPhone} />
                        <h3>How to use</h3>
                        <p>
                          After purchase your can get an email notification and
                          voucher will be available within your account
                        </p>
                      </div>

                      <button
                        type="button"
                        className="button add_to_cart"
                        onClick={this.addToCartVoucherMe.bind(this)}
                      >
                        Add To Cart
                      </button>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>        
      </div>
    {isOpen && (
          <Lightbox
            mainSrc={popUpImage[photoIndex]}
            nextSrc={popUpImage[(photoIndex + 1) % popUpImage.length]}
            prevSrc={
              popUpImage[
                (photoIndex + popUpImage.length - 1) % popUpImage.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + popUpImage.length - 1) % popUpImage.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % popUpImage.length,
              })
            }
          />
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (
      catslug,
      subcatslug,
      outletid,
      sortByField,
      sortByValue
    ) => {
      dispatch({
        type: GET_PRODUCT_LIST,
        catslug,
        subcatslug,
        outletid,
        sortByField,
        sortByValue,
      });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
