/* eslint-disable */
if (location.protocol !== "https:") {
  var locationULR = location.href.replace("", "");
  location.href = locationULR.replace("http://", "https://");
} else if (location.protocol === "https:") {
  var locationULR = location.href;
  if (locationULR.indexOf("www.") < 0) {
    location.href = "https://www." + locationULR.replace("https://", "");
  }
}
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";
import TagManager from "react-gtm-module";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
import Products from "./components/Products/Products";

import Menu from "./components/Pages/Menu";
import Press from "./components/Pages/Press";
import Career from "./components/Pages/Careers";
import Events from "./components/Pages/Events";

import Reservations from "./components/Pages/Reservations";

import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Pages from "./components/Pages/Pages";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Rewards from "./components/Myaccount/Rewards";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Logout from "./components/Myaccount/Logout";
import Myvouchers from "./components/Myaccount/Myvouchers";
import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";

const store = getStore();
TagManager.initialize({ gtmId: "GTM-TC8ZCCHS" });
render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/index.html" component={Home} />
        <Route exact path="/" component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/about" component={Home} />
        <Route path="/location" component={ContactUs} />
        <Route path="/about-us" component={Home} />

        <Route path="/menu" component={Home} />
        <Route path="/press" component={Press} />
        <Route path="/awards" component={Press} />
        <Route path="/events" component={Events} />
        <Route path="/careers" component={Career} />

        <Route path="/Reservation" component={Reservations} />

        <Route
          path={"/orders/:slugType/:slugValue/:proValue"}
          component={Products}
        />
        <Route path={"/orders/:slugType/:slugValue"} component={Products} />
        <Route path="/orders" component={Products} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/terms-and-conditions" component={Pages} />
        <Route path="/gallery" component={Pages} />
        <Route path="/faq" component={Pages} />
        <Route path="/locations" component={Pages} />
        <Route path="/our-team" component={Pages} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/outlet" component={Outlets} />
        <Route path="/myvouchers" component={Myvouchers} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/logout" component={Logout} />
        <Route path="/:page_slug" component={Pages} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />

        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
