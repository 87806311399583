/* Live */
export const appId = "E1BF57EE-2BB3-458F-9B60-B3C6F418C896";

export const appName = "The Beirut";

export const apiUrl = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlCtrng = "https://ccpl.ninjaos.com/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
/*export const baseUrl = "http://www.beirut.promobuddy.asia/";*/
export const baseUrl = "https://beirut.com.sg/";
export const timThumpUrl = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.com/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";

export const stripeImage = "/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Beirut-Grill";

export const fbAppId = "1590378584482925";
export const googleAppId =
  "665847346243-uavd1pjkv66pbg5ueg8ea5ms40rt6btg.apps.googleusercontent.com";

export const stripeKey = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "beirutgrill";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
