/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";

import { stripslashes } from "../../Helpers/SettingHelper";
import bannerlogoImg from "../../../common/images/logo.png";
import sampleBnrImg from "../../../common/images/banner.jpg";
import ReservationImg from "../../../common/images/reservationimg.svg";
import DeliveryImg from "../../../common/images/deliveryimg.svg";
import TakeawayImg from "../../../common/images/takeawayimg.svg";

import {
  appId,
  appName,
  apiUrl,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
} from "../../Helpers/Config";

import { GET_BANNER_LIST } from "../../../actions";

var Parser = require("html-react-parser");

class HomeBanner extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getBannerList();
  }

  componentDidMount() {
    /*console.log('DidMount');*/
  }

  /* componentDidUpdate() {
    console.log('this called last - after page loading');
    } */

  render() {
    let bannerArr = this.props.banner;
    let bannerlist = [];
    let bannerimagesource = "";
        console.log(bannerArr)

    if (Object.keys(bannerArr).length > 0) {
      if (bannerArr[0].status === "ok") {
        bannerlist = bannerArr[0].result_set;
        bannerimagesource = bannerArr[0].common.image_source;
      }
    }

    var settingsGallery = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots:false,
      speed: 2000,
      autoplay: true,
      arrow: true,
    };

    return (
      <div className="home-banner">
        {Object.keys(bannerlist).length > 0 ? (
          <>
          <Slider {...settingsGallery}>
            {bannerlist.map((banner, index) => {
              return (
                <div key={index}>
                  <img
                    src={bannerimagesource + banner.banner_image}
                    alt="Banner"
                  />                  
                </div>
               );
            })}
          </Slider>
          <div className="homebanner-content">
            <div className="homebanner-content-inner">
              <h4>since 2009</h4>
              <h2>Beirut Grill</h2>
              <p>                      
              Multiple Award-Winning Middle-Eastern & Lebanese Restaurant in the heart of Singapore</p>

              <div className="homebanner-button">
                <a className="btn" href="reservation"
                 title="RESERVATION"><img src={ReservationImg} alt="reservation" />reservation</a>
                  <a className="btn" href="#"
                  onClick={this.props.chooseAvailabilityFun.bind(
                      this,
                      deliveryId
                    )}
                   title="DELIVERY"><img src={DeliveryImg} alt="delivery" />delivery</a>
                  <a className="btn" href="#"
                  onClick={this.props.chooseAvailabilityFun.bind(
                      this,
                      pickupId
                    )}
                   title="TAKEAWAY"><img src={TakeawayImg} alt="takeaway" />takeaway</a>
              </div>
            </div>
          </div>
          </>
        ) : (
          <div className="">
            <img src={sampleBnrImg} alt="Banner" />
            {/*<div className="loader-sub-div"></div> */}
            <div className="homebanner-content">
              <div className="homebanner-content-inner">
                <h4>since 2009</h4>
                <h2>beirut grill</h2>

                <div className="homebanner-button">
                  <a className="btn" href="reservation"
                   title="RESERVATION"><img src={ReservationImg} alt="reservation" />reservation</a>
                  <a className="btn" href="#"
                  onClick={this.props.chooseAvailabilityFun.bind(
                      this,
                      deliveryId
                    )}
                   title="DELIVERY"><img src={DeliveryImg} alt="delivery" />delivery</a>
                  <a className="btn" href="#"
                  onClick={this.props.chooseAvailabilityFun.bind(
                      this,
                      pickupId
                    )}
                   title="TAKEAWAY"><img src={TakeawayImg} alt="takeaway" />takeaway</a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(HomeBanner);
