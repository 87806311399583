/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import footerLogo from "../../common/images/logo.png";
import footerMap from "../../common/images/f-map.png";
import footertel from "../../common/images/f-tele.png";
import footeremail from "../../common/images/f-email.png";

import footerfacebook from "../../common/images/f-facebook.png";

import footerinstagram from "../../common/images/f-instagram.png";

import { appId, apiUrl, appName } from "../Helpers/Config";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";

import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";

import { GET_STATIC_BLOCK } from "../../actions";
import cookie from "react-cookies";
import axios from "axios";
var qs = require("qs");

var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerBlocks: "",
      footerlink: "",
      footertopinfo: "",
      newsLetterEmail: "",
      isChecked: false,
      appName: this.props.appName,
    };
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });

    //trigger login in footer menu
    if (!cookie.load("UserId")) {
      setTimeout(function () {
        $(document).find(".make_login").attr("href", "#login-popup");
        $(document)
          .find(".make_login")
          .addClass("open-popup-link new_login_popup");
      }, 1000);
    }

    $(document).on("click", ".new_login_popup", function () {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    });
    //trigger login in footer menu
  }

  checkEmail(){
      if (this.state.newsLetterEmail !== "" && this.state.isChecked == true) {

        var postObject = {
          app_id: appId,
          email: this.state.newsLetterEmail,
        };
        axios
          .post(apiUrl + "newsletter/subscription_new", qs.stringify(postObject))
          .then((res) => {
            if (res.data.status === "ok") {
              var magnfPopup = $.magnificPopup.instance;
              showAlert("Success", "Newsletter Subscribed", magnfPopup);
              setTimeout(function () {
                window.location.reload();
              }, 3000);
            }else{
              showAlert("Error", "Enter a valid email", magnfPopup);
            }
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          });

      }

  }

  handleChangeemail(event){
    this.setState({newsLetterEmail : event.target.value});
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      var footerTopInfoPas = "";
      console.log(PropsData.staticblack);
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "footer-top-info") {
            footerTopInfoPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      footerTopInfoPas =
        footerTopInfoPas !== "" ? Parser(footerTopInfoPas) : footerTopInfoPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
        footertopinfo: footerTopInfoPas,
      });
    }
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="footer-top">
          <Link to={"/"} title="Beirut-Grill">
            <img src={footerLogo} />
          </Link>
        </div>

          <div className="footer-nav-info">
            <div className="container-full">
              {this.state.footerlink}
              <div className="f-socail">
                  <div className="footer-newletter">
                    <div className="f-newletter-form">
                      <input type="text" onChange={this.handleChangeemail.bind(this)} value={this.state.newsLetterEmail} placeholder="Email Address" />
                      <button type="submit" onClick={this.checkEmail.bind(this)} className="button">Subscribe</button>
                    </div>
                    <div className="f-newletter-bottom">
                      <input type="checkbox" onChange={this.toggleChange} className="subscribe_accept" />
                      <label> 
                        Hello there! Join our mailing list now to stay updated on new menus, seasonal specials, events, happenings & more at Beirut Grill.
                      </label>
                    </div>
                  </div>
              </div>
            </div>
          </div>

        {this.state.footertopinfo}

        <div className="copyright-section">
          <p>Copyright All rights reserved @ {yearSp} Rekhi Enterprises Pte Ltd</p>
          <ul>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
          
        </div>

        <div className="scrolltop" id="scrollbutton">
          <a href="/" className="disbl_href_action">
            <span>
              <i className="fa fa-angle-double-up ars"></i>
              <i className="fa fa-angle-up ars1"></i>
              <i className="fa fa-angle-up ars2"></i>
            </span>
          </a>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
