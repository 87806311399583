/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import Slider from "react-slick";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import FeaturedProducts from "./HomeSubCompo/FeaturedProducts";
import PromotionProducts from "./HomeSubCompo/PromotionProducts";
import ProductDetail from "../Products/ProductDetail";

import {
  appId,
  appName,
  apiUrl,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_STATIC_BLOCK,
  GET_NORMAL_POPUP,
  GET_CATEGORY_LIST,
  GET_MENU_NAVIGATION,
  GET_TESTIMONIALS,
} from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  stripslashes,
  showAlert 
} from "../Helpers/SettingHelper";

import newletterImg from "../../common/images/newletter-bg.jpg";
import profileImg from "../../common/images/testi-profile.png";
import promoImg from "../../common/images/product.jpg";

import deliverypopImg from "../../common/images/Delivery_white.svg";
import takeawaypopImg from "../../common/images/Takeaway_white.svg";
import OwlCarousel from "react-owl-carousel2";

import reviewImg from "../../common/images/review.png";
import reviewiconImg from "../../common/images/review-icon.png";
import reviewauthoImg from "../../common/images/review-author.png";
import reviewauthooneImg from "../../common/images/review-author-1.png";
import reviewauthotwoImg from "../../common/images/review-author-2.png";

import "../../common/css/owl.carousel.css";
var Parser = require("html-react-parser");
var qs = require("qs");
const options = {
  items: 3,
  autoplay: false,
  autoWidth: true,
  margin: 15,
  loop: true,
};

var settingsGallery1 = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  className: "center",
  centerPadding: "0",
  responsive: [
    {
      breakpoint: 1191,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class Home extends Component {
  constructor(props) {
    super(props);

    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    this.state = {
      users: [],
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      testimonials:[], 
      testimonialpath:[], 
      testimonialsBlks: "",
      doorStepBlck: "",
      ourStoryBlck: "",
      aboutUsBlock: "",
      menuBlock: "",
      viewProductFlg: "no",
      viewProductSlug: "",
      normalpopuplist: [],
      normalpopupdata: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      displayType: "all",
    };

    this.props.getSettings();
    this.props.getCategoryList();
    this.props.getMenuNavigation();
    this.props.getNormalPopup();
    this.props.getStaticBlock();
    this.props.getTestimonials();
    if(window.location.pathname == '/index.html'){
      window.location.href = '/';
    }

  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var testimonials = "",
        doorStepBlock = "",
        aboutUsBlock = "",
        menuBlock = "",
        ourStoryBlock = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "testimonials") {
            testimonials = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "door-step-block") {
            doorStepBlock = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "our-story-block") {
            ourStoryBlock = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "about-us-section") {
            aboutUsBlock = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "menu-section") {
            menuBlock = data.staticblocks_description;
            return "";
          }
        });
      }
      testimonials =
        testimonials !== "" && testimonials !== null
          ? Parser(testimonials)
          : testimonials;
      doorStepBlock =
        doorStepBlock !== "" && doorStepBlock !== null
          ? Parser(doorStepBlock)
          : doorStepBlock;
      ourStoryBlock =
        ourStoryBlock !== "" && ourStoryBlock !== null
          ? Parser(ourStoryBlock)
          : ourStoryBlock;
      aboutUsBlock =
        aboutUsBlock !== "" && aboutUsBlock !== null
          ? Parser(aboutUsBlock)
          : aboutUsBlock;
      menuBlock =
        menuBlock !== "" && menuBlock !== null
          ? Parser(menuBlock)
          : menuBlock;
      this.setState({
        staticblacks: PropsData.staticblack,
        testimonialsBlks: testimonials,
        doorStepBlck: doorStepBlock,
        ourStoryBlck: ourStoryBlock,
        menuBlock: menuBlock,
        aboutUsBlock: aboutUsBlock
      });
    }

    if (PropsData.testimonials !== undefined && PropsData.testimonials !== this.state.testimonials) {

      this.setState({
        testimonials: PropsData.testimonials,
        testimonialpath: PropsData.testimonialpath,
      });

  }


    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }
  }

  chooseAvailability(availability) {
    return false;
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        // $.magnificPopup.open({
        //   items: {
        //     src: "#warning-popup",
        //   },
        //   type: "inline",
        // });
        // return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        // $.magnificPopup.open({
        //   items: {
        //     src: "#warning-popup",
        //   },
        //   type: "inline",
        // });
        // return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      /*cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/catering");*/
      return false;
    } else if (availability === reservationId) {
      /*cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");*/
      return false;
      /* popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  chooseAvailabilityEnvtFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      /*  && cartTotalItems > 0 */
      if (defaultAvilTy === cateringId) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      /*$.magnificPopup.close();
      this.props.history.push('/orders');
      return false;*/
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      /*cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/");*/
      return false;
    } else if (availability === reservationId) {
      /*cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");*/
      return false;
      /*popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  checkActiveDivHm(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    } else if (
      availability == avlType &&
      (avlType === cateringId || avlType === reservationId)
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  componentDidMount() {
     $(document).find('body').addClass('homepopupopen');
     if(cookie.load('outletchosen') == '' || typeof cookie.load('outletchosen') == 'undefined'){
      // $.magnificPopup.open({
      //   items: {
      //     src: '#home-popup',
      //   },
      //   type: "inline",
      // });
      cookie.save("outletchosen", 'Yes', { path: "/" });
     }

     if(cookie.load('triggerMenu') != '' && typeof cookie.load('triggerMenu') != 'undefined'){
      setTimeout(function(){
        $('html,body').animate({
        scrollTop: $('#'+cookie.load('triggerMenu')).offset().top - 100
        }, 'slow');
        $('#close_mobile_menu').trigger('click');
        cookie.remove("triggerMenu");
      },1000);
     }

    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop");
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger");
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    var RctThis = this;
    $("body")
      .off("click", ".act_order_popup")
      .on("click", ".act_order_popup", function (e) {
        e.preventDefault();

        var odrType = $(this).attr("data-acturl");
        var availabilityId = "";
        if (odrType === "delivery") {
          availabilityId = deliveryId;
        } else if (odrType === "pickup") {
          availabilityId = pickupId;
        } else if (odrType === "catering") {
          availabilityId = cateringId;
        } else if (odrType === "reservation") {
          availabilityId = reservationId;
        }

        if (availabilityId !== "") {
          RctThis.chooseAvailability(availabilityId);
        } else if (availabilityId === "" && odrType === "ordernow") {
          // $.magnificPopup.open({
          //   items: {
          //     src: "#order-popup",
          //   },
          //   type: "inline",
          // });
        }
      });
  }

  closeHomepop(){
    $.magnificPopup.close();
    $('#order-popup').fadeOut(450);
    cookie.save("skipbrowse", "Yes", {
        path: "/",
      });
    setTimeout(function(){
      $('#order-popup').modal("hide");
    }, 800);
    this.props.history.push("/orders");
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    }
    if (field === "firstcategory" && value !== "") {
      this.setState({ firstcategory: value });
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      Object.keys(this.state.normalpopupdata).length > 0 &&
      cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      var normalpopupIds = cookie.load("normalpopupIds");
      var normalpopupIdsNew =
        normalpopupIds != "" && normalpopupIds != undefined
          ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
          : this.state.normalpopupdata.normalpopup_id;
      var normalpopupIdArr = [];
      normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
      cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function () {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    if (
      (cookie.load("promoPopupTrigger") === "Yes" ||
        (otherPageActTrigger === "no" &&
          this.state.normalpopup_status === "no" &&
          Object.keys(this.state.normalpopupdata).length === 0)) &&
      cookie.load("mailpopopuptrg") !== "yes"
    ) {
      cookie.save("mailpopopuptrg", "yes", { path: "/" });
      cookie.remove("promoPopupTrigger");
      $.magnificPopup.open({
        items: {
          src: "#promo-check-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
      });
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of Beirut-Grill. Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }

  setdisplayType(displayType) {
    this.setState({ displayType: displayType });
  }

  handleChangeemail(event){
    this.setState({newsLetterEmail : event.target.value});
  }


  checkEmail(){
      if (this.state.newsLetterEmail !== "" && this.state.isChecked == true) {

        var postObject = {
          app_id: appId,
          email: this.state.newsLetterEmail,
        };
        axios
          .post(apiUrl + "newsletter/subscription_new", qs.stringify(postObject))
          .then((res) => {
            if (res.data.status === "ok") {
              var magnfPopup = $.magnificPopup.instance;
              showAlert("Success", "Newsletter Subscribed", magnfPopup);
              $.magnificPopup.open({
                items: {
                  src: ".alert_popup",
                },
                type: "inline",
              });
              setTimeout(function () {
                window.location.reload();
              }, 3000);
            }else{
              showCustomAlert("error", "Enter a valid email",);
            }
          });

      }

  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  chooseAvailabilityFun(availability, event) {
    // this.closeHomepop()
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      /*  && cartTotalItems > 0 */
      if (defaultAvilTy === cateringId) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      /*$.magnificPopup.close();
      this.props.history.push('/orders');
      return false;*/
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      /*cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/");*/
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
      /*popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }



  render() {
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }
      var Testimonial = {
      centerMode: true,
      slidesToShow: 3,
      centerPadding: '0px',
      dots: true,
      arrows: false,
      autoplay: true,
      responsive: [
      {
        breakpoint: 900,
        settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1
        }
      }
      ]
      };
    return (
      <>
      <div className="home-main-div">
        {/* Header section */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />

        {/* Home banner section */}
        <HomeBanner
          chooseAvailabilityFun={this.chooseAvailabilityFun}
         />

        {/*<section className="home-choose-type">
          <div className="container">
            <ul>
              <li className={this.checkActiveDivHm(deliveryId)}>
                <div className="hct-img">
                  <img src={yscooterImg} />
                </div>
                <div className="hct-content">
                  <h2>Delivery</h2>
                  <a
                    href="/"
                    onClick={this.chooseAvailabilityEnvtFun.bind(
                      this,
                      deliveryId
                    )}
                    className="button"
                  >
                    Order Now
                  </a>
                </div>
              </li>
              <li className={this.checkActiveDivHm(pickupId)}>
                <div className="hct-img">
                  <img src={ytakeImg} />
                </div>
                <div className="hct-content">
                  <h2>Takeaway</h2>
                  <a
                    href="/"
                    onClick={this.chooseAvailabilityEnvtFun.bind(
                      this,
                      pickupId
                    )}
                    className="button"
                  >
                    Order Now
                  </a>
                </div>
              </li>
              {/*<li className={this.checkActiveDivHm(cateringId)}>
                <div className="hct-img">
                  <img src={ycateringImg} />
                </div>
                <div className="hct-content">
                  <h2>Catering</h2>
                  <a
                    href="javascript:void(0)"
                    className="button"
                  >
                    Coming Soon
                  </a>
                </div>
              </li>*/}
            {/*</ul>
          </div>
        </section>*/}

        <section className="home-restaurant" id="about-us">
          <div className="container">
            <h2>ABOUT US</h2>
            <div className="home-restaurant-inner-box">
              {this.state.aboutUsBlock}
            </div>

            <div className="home-restaurant-button">
              <a className="btn" href="/press" title="PRESS">press</a>
              <a className="btn" href="/awards" title="AWARDS">AWARDS</a>
            </div>
          </div>
        </section>

        <section className="home-menu" id="menu-section">
        <div className="home-menu-inner">
          <div className="container">
              {this.state.menuBlock}
              <div class="product-info-div-home">
                <div class="products-ordernow-action">
                  <a class="button" onClick={this.chooseAvailabilityFun.bind(this, reservationId)} href="javascript:void(0);">reserve now</a>
                </div>
              </div>
          </div>
          </div>
        </section>

          {Object.keys(this.state.testimonials).length > 0 &&

            <section className="home-review">
              <div className="container">
                <h2>customer reviews</h2>
                <div className="h-review-box">
                  <Slider {...Testimonial}>
                    {Object.keys(this.state.testimonials).length && this.state.testimonials.map((testimonial, index) => {
                    return (<div key={index+1000}>
                  <div className="h-review-left-one">
                    <div className="h-review-one">
                      <ul className="h-review-ul">
                        <li><img alt="MENUIMAGE"  src={reviewImg} /></li>
                      </ul>
                      <ul className="h-review-content">
                        <li className="h-review-heading">{testimonial.testimonial_tittle}</li>
                        <li className="h-review-para">
                          {stripslashes(testimonial.testimonial_description)}
                        </li>
                      </ul>
                    </div>
                    <div className="h-review-bottom-box">
                      <ul>
                        <li>
                          <div class="review-author">
                            <div class="review-author-img"><img alt="REVIEW AUTHOR"  src={this.state.testimonialpath.image_source+testimonial.testimonial_image} /></div>
                            <div class="review-author-content">
                              <h5>{testimonial.testimonial_company}</h5>
                              <p>September 27, 2020</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  </div>
                  );
                    })}  
                  </Slider>

                </div>
              </div>
            </section>
 
            }

        {/* Footer section */}
        <Footer appName={appName} />

        <ProductDetail
          productState={this.state}
          sateValChange={this.sateValChange}
          classNameAdd="modal fade modal-dialog-tgrfromhome"
        />

        {/*Normal popup Start*/}
        <div
          id="normal-popup"
          className="white-popup mfp-hide popup_sec normal-popup"
        >
          <div className="normal_popup_alert">
            {this.state.normalpopup_status === "yes" &&
              Object.keys(this.state.normalpopupdata).length > 0 && (
                <div className="normal_popup_cont">
                  {Parser(this.state.normalpopupdata.normalpopup_description)}
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}

        {/*Promo Check Email popup Start*/}
        <div
          id="promo-check-popup"
          className="white-popup mfp-hide popup_sec promo-check-popup"
        >
          <div className="promopopup-maindiv">
            <div className="promopopup-lhs">
              <div className="frm-action-div">
                <div className="frm-top-title">
                  <h3>Join our email list</h3>
                  <p>Enter your Email address to receive your promocode</p>
                </div>

                <div className="frm-inputbtn-div">
                  <div className="form-group">
                    <div className="focus-out">
                      <label>Email Address</label>
                      <input
                        type="email"
                        className="form-control input-focus"
                        value={this.state.promo_mail_id}
                        onChange={this.handleEmailChange.bind(this)}
                      />
                      {this.state.promomail_error !== "" && (
                        <div id="promomail-error">
                          {this.state.promomail_error}
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={this.sendPromoMailFun.bind(this)}
                    className="button promomailpopup-cls"
                  >
                    Subscribe
                  </button>
                </div>

                <div className="frm-bottom-text">
                  <p>
                    SIGN UP FOR EXCLUSIVE UPDATES, NEW PRODUCTS, AND
                    INSIDER-ONLY DISCOUNTS
                  </p>
                </div>
              </div>
            </div>

            <div className="promopopup-rhs">
              <img src={newletterImg} />
            </div>
          </div>
        </div>
        {/*Promo Check Email popup - End*/}

        {this.state.normalpopupstatus !== "initial" &&
          (showNormalPopup === "yes" || showPromoPopup === "yes") &&
          this.triggerNormalPopup("loading")}
      </div>

      <div
          id="home-popup"
          className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup home-popup-block"
        >
          <div className="order-body">
            <div className="self_popup_hea_row">
              <div className="self_popup_hea_col_left">
              </div>
              <div className="self_popup_hea_col">
              </div>
            </div>

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="H-popup-inner">
                      <div className="H-popup-inner-content">
                        <h2>$10 off for your first order</h2>
                        <p> Sign up for our mailing list and take $10 off your <br />first takeaway or delivery order via our website!</p>
                        {/*<a class="btn" href="/orders" title="ORDER NOW">order now</a>
                        <div className="outlet_error"></div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="f-socail">
                  <div className="footer-newletter">
                    <div className="f-newletter-form">
                      <input type="text" onChange={this.handleChangeemail.bind(this)} value={this.state.newsLetterEmail} placeholder="Email Address" />
                      <button type="submit" onClick={this.checkEmail.bind(this)} className="button">Subscribe</button>
                    </div>
                    <div className="f-newletter-bottom">
                      <input type="checkbox" onChange={this.toggleChange} className="subscribe_accept" />
                      <label> 
                        Hello there! Join our mailing list now to stay updated on new menus, seasonal specials, events, happenings & more at Beirut Grill.
                      </label>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

      </>

    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var tempArr = Array();
  var navigateRst = Array();
  var navigateCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }

  var testimonials = Array();
  var testimonialpath = Array();
  if (Object.keys(state.testimonials).length > 0) {
    testimonials  = state.testimonials[0].result_set;
    testimonialpath = state.testimonials[0].common;
  }

  return {
    globalsettings: state.settings,
    categorylist: state.categorylist,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    staticblack: blacksArr,
    normalpopuplist: normalPopupArr,
    testimonials: testimonials, 
    testimonialpath: testimonialpath,
    normalpopupstatus: normalPopupStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getCategoryList: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
    getTestimonials: () => {
      dispatch({ type: GET_TESTIMONIALS });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
