/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId,reservationId } from "../Helpers/Config";
var Parser = require("html-react-parser");
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import cookie from "react-cookies";

import { connect } from "react-redux";
import innerBanner from "../../common/images/inner-banner.jpg";

import loginLogo from "../../common/images/logo.png";

import userImg from "../../common/images/reservation-men.svg";


import {
  GET_PICKUP_OUTLETS,
  GET_CONTACTDATA,
  GET_STATIC_BLOCK,
  GET_CONTACTCONTENT,
  GET_GLOBAL_SETTINGS,
} from "../../actions";

var Parser = require("html-react-parser");

class Press extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staticblocks: [],
      reservationBlk: ''
    }
    this.props.getStaticBlock();
  }

  componentDidMount() {
    $(document).find('#site-header-wrapper').hide();
        cookie.save(
          "defaultAvilablityId",
          reservationId,
          { path: "/" }
        );
        cookie.remove("orderOutletId");
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var reservation = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "reservation") {
            reservation = data.staticblocks_description;
            return "";
          }
        });
      }
      reservation =
        reservation !== "" && reservation !== null
          ? Parser(reservation)
          : reservation;
      this.setState({
        staticblacks: PropsData.staticblack,
        reservationBlk: reservation
      });
    }

  }

  render() {
    return (
      <div className="menumain-div">
        <Header />

        <div className="reservation-fullmain-div">
          {/*<div className="common-inner-blckdiv">
            <div className="container">
              <div className="common-inner-banner">
                <div className="inner-banner-content">
                  <p>Reservation</p>
                </div>
              </div>
            </div> 
          </div>*/}

          <div className="reservation-body-div">
            <div className="container">
              <div className="reservation-fullinner-div">
                <div className="reservation-lhs">
                  <iframe width="100%" height="100%" src="https://book.chope.co/booking?rid=beirutgrill1806brg&source=rest_beirut.com.sg" />
                </div>

                <div className="reservation-Rhs">
                  <div className="reservation-Rhs-heading">
                    <h2>reservation</h2>
                    {this.state.reservationBlk}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  
  return {
    outlets: state.outlets,
    globalsettings: state.settings,
    staticblack: blacksArr,
    contactdata: state.contactdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

Press.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Press)
);
